<template>
  <div class="eden-transaction--invoice">
    <div class="eden-transaction--invoice__header">
      <img :src="getImage('eden_logo.svg')" alt="Eden" />
      <div class="customer-info">
        <div>
          <p>Email:</p>
          <span>{{ data.user_email }}</span>
        </div>
        <div>
          <p>Date:</p>
          <span>{{ formatDate(data.date_paid) }}</span>
        </div>
        <div>
          <p>Transaction type:</p>
          <span>{{ formatText(data.payment_type) }}</span>
        </div>
      </div>
    </div>
    <div class="eden-transaction--invoice__address">
      <p>Eden Life</p>
      <p>
        Km 16 Ikorodu-Ososun Rd, <br />
        Ojota 101233, <br />
        Lagos, Nigeria.
      </p>
    </div>
    <div class="eden-transaction--invoice__content">
      <table>
        <tr>
          <th>Service</th>
          <th>Frequency</th>
          <th>Quantity</th>
          <th>Amount</th>
        </tr>
        <template v-if="data.break_down && data.break_down.length">
          <tr v-for="(item, i) in data.break_down" :key="i">
            <td>
              {{
                item.service.name
                  ? formatText(item.service.name)
                  : formatText(item.service)
              }}
            </td>
            <td>{{ frequency(item.frequency) }}</td>
            <td v-if="item.quantity">
              {{ item.quantity }}
              {{
                item.service.name
                  ? quantityLabels[item.service.name]
                  : quantityLabels[item.service]
              }}
            </td>
            <td v-else>-</td>
            <td>
              {{ formatPrice(item.amount) }}
            </td>
          </tr>
        </template>
        <template v-else>
          <tr v-for="(service, i) in data.service" :key="i">
            <td>{{ formatText(service.name) }}</td>
            <td>-</td>
          </tr>
        </template>
        <tr>
          <td><span class="font-lg">Total</span></td>
          <td colspan="3">
            <span class="text-bold font-lg">{{
              formatPrice(data.amount)
            }}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransactionBreakdown",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      quantityLabels: {
        beauty: " services per month",
        cleaning: "",
        laundry: " bags",
        meal: " meals per week",
      },
    };
  },
  methods: {
    frequency(item) {
      if (item) {
        return this.formatText(this.frequencyLabel(item.toLowerCase()));
      } else {
        return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-transaction--invoice {
  width: 100%;
  padding: 10px 40px 20px;
  border: 1px solid #e4e8e6;
  margin: -15px auto;
  box-sizing: border-box;
  border-radius: 12px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    img {
      height: 40px;
    }

    .customer-info {
      > div {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        p {
          min-width: 180px;
          font-weight: 600;
          font-size: 0.75rem;
          color: #21312a;
        }

        span {
          font-size: 0.75rem;
          color: #21312a;
        }
      }
    }
  }

  &__address {
    margin: 40px 0;

    p {
      font-size: 0.75rem;
      color: #21312a;
      line-height: 1.6;

      &:first-child {
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }

  &__content {
    table {
      border: none;
      border-collapse: collapse;
      width: 100%;
    }

    th {
      font-weight: 600;
      background: #e4e8e660;
      border: none !important;

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }
    }

    td,
    th {
      border: 1px solid rgba(228, 232, 230, 0.4);
      text-align: left;
      color: #21312a;
      padding: 14px 20px;
      font-size: 0.825rem;
    }

    tr:last-child {
      border: 1px solid rgba(228, 232, 230, 0.4);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      td {
        border: none;
        padding: 22px;

        &:first-child {
          border-top-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
        }
      }
    }
  }
}
</style>
