<template>
  <div>
    <el-dialog
      :title="`Transaction ${
        action == 'view-receipt' ? 'Receipt' : 'Invoice Preview'
      }`"
      :visible.sync="shouldShow"
      width="60%"
      @close="closeEvent"
      class="preview"
    >
      <transaction-breakdown :data="data" />
      <div
        v-if="action === 'view-receipt'"
        class="is-flex is-justify-center mt-2"
      >
        <el-button type="primary" :loading="loading" @click="download"
          >Download</el-button
        >
      </div>
      <div
        v-if="action === 'generate-invoice'"
        class="is-flex is-justify-center mt-3"
      >
        <el-button plain @click="closeEvent">Continue editing</el-button>
        <el-button type="primary" :loading="loading" @click="saveAndSendInvoice"
          >Save and Send</el-button
        >
      </div>
    </el-dialog>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :paginate-elements-by-height="1400"
      :filename="fileName"
      :pdf-quality="4"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="edenInvoice"
    >
      <section slot="pdf-content">
        <transaction-breakdown :data="data" />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import transactions from "@/requests/subscriptions/transactions";
import VueHtml2pdf from "vue-html2pdf";
import TransactionBreakdown from "@/components/Subscriptions/Transactions/Breakdown/TransactionBreakdown";

export default {
  name: "TransactionPreview",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      default: "view-receipt",
    },
  },
  components: { TransactionBreakdown, VueHtml2pdf },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    fileName() {
      return `Receipt_${this.data.tnx_reference}`;
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    download() {
      this.loading = true;
      setTimeout(() => {
        this.$refs.edenInvoice.generatePdf();
        this.loading = false;
      }, 1500);
    },
    saveAndSendInvoice() {
      transactions
        .addTxnInvoice(this.data)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.loading = false;
            this.$emit("success");
            this.closeEvent();
          } else {
            this.$message.error(response.data.message);
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  max-height: 100vh !important;
}
</style>
